<template>
  <div
    class="menu-item"
    @mouseover="isHovering = true"
    @mouseleave="isHovering = false"
  >
    <div class="img-label">
      <img
        :class="serviceRoute"
        :src="icon"
        :alt="serviceName"
      >
      <div>
        <span v-if="!collapsed">{{ serviceName }}</span>
      </div>
      <div
        v-if="nbNotifications > 0 "
        class="pellet"
        :class="serviceRoute"
      >
        {{ nbNotifications }}
      </div>
    </div>
    <!-- Indicator for selected service-->
    <div
      v-if="$route.name === serviceRoute"
      class="selected-service"
    />
    <div
      v-else-if="serviceRoute !== 'account' && serviceRoute !== 'logout'"
      class="unselected-service"
      :class="{'not-collapsed' : !collapsed}"
    />

    <div
      v-if="collapsed && isHovering && ($route.name !== serviceRoute)"
      class="hovered-name"
    >
      {{ serviceName }}
    </div>
  </div>
</template>

<script>

export default {
  name: 'SideMenuItem',
  props: {
    serviceName: {
      type: String, required: true
    },
    serviceRoute: {
      type: String, required: true
    },
    icon: {
      type: String, required: true
    },
    collapsed: {
      type: Boolean,
      default: false
    },
    nbNotifications: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      isHovering: false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@design';

.menu-item {
  position: relative;
  color: #FBFBFD;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  cursor: pointer;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .img-label {
    display: flex;
    align-items: center;
    position: relative;

    img {
      width: 34px;
      height: 34px;

      &.account {
        border-radius: 50px;
      }
    }

    .pellet {
      position: absolute;
      top: 0;
      left: 23px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 10px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 15px;
      color: $color-cadyco-dark-text;
      height: 14px;
      width: 14px;
      border-radius: 7px;

      &.messaging {
        background-color: #FBDC47;
      }

      &.catalog {
        left: 35px;
        background-color: #FF8BAA;
      }
    }

    span {
      padding-left: 20px;
      font-size: 14px;
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  .selected-service {
    float: right;
    height: 100%;
    width: 5px;
    background-color: $color-menu-selected-item;
  }

  .hovered-name {
    position: absolute;
    top:0;
    left: 100%;
    height: 100%;
    width: 160px;
    background-color: $color-menu-bg;
    box-shadow: 0 2px 16px 0 rgba(0,0,0,0.1);
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:hover {
    .unselected-service {
      &.not-collapsed {
        float: right;
        height: 100%;
        width: 5px;
        background-color: $color-menu-selected-item;
      }
    }
  }
}

</style>
