<template>
  <div
    class="popup"
    :class="device + '-popup'"
  >
    <BaseIcon
      v-if="mq.phone"
      name="caret-down"
      class="white-caret"
    />
    <BaseIcon
      v-else
      name="caret-up"
      class="white-caret"
    />
    <Catalog
      v-if="catalog"
      :catalog="catalog"
      class="catalog"
    />
  </div>
</template>

<script>
import BaseIcon from '@components/Base/BaseIcon'
import Catalog from '@components/Indicator/Catalog/Catalog'

export default {
  name: 'CatalogPopover',
  components: {
    BaseIcon,
    Catalog
  },
  inject: ['mq'],
  emits: ['close'],
  computed: {
    catalog () {
      return this.$store.state.catalog.userCatalog
    },
    device () {
      if (this.mq.phone) {
        return 'phone'
      } else {
        return 'desktop'
      }
    }
  },
  mounted () {
    if (this.$store.state.catalog.userCatalog === undefined) {
      this.$store.dispatch('catalog/initUserCatalog')
    }
    window.addEventListener('click', this.clickOutside)
  },
  beforeUnmount () {
    window.removeEventListener('click', this.clickOutside)
  },
  methods: {
    clickOutside (e) {
      const self = this
      if (self.$el && !self.$el.contains(e.target)) {
        this.$emit('close')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@design';

.popup {
  position: absolute;
  background-color: #fff;
  box-shadow: 0 2px 14px 0 rgba(0,0,0,0.1);
  padding: 20px;
}

.catalog {
  overflow: auto;
  width: 40vw;
  max-height: 70vh;
}

.white-caret {
  position: absolute;
  color: #fff;
}

.desktop-popup {
  top: calc(#{$desktop-search-bar-height} + 10px);
  right: 0;

  .white-caret {
    top: -27px;
    right: 15px;
    font-size: 50px;
  }
}

.phone-popup {
  bottom: calc(#{$mobile-search-bar-height} + 10px);
  right: 0;
  padding: 5px;

  .white-caret {
    bottom: -17px;
    right: 15px;
    font-size: 30px;
  }

  .catalog {
    overflow: auto;
    width: 90vw;
    max-height: 50vh;
  }
}

</style>

<style lang="scss">
/* Overwrite catalog css to display in popup */
.popup .catalog {
  .header h3 {
    margin-top: 10px;
    margin-bottom: 5px;
    font-size: 1.1rem;
  }

  .content {
    background-color: #fff;
    padding: 5px;

    h3 {
      margin: 0;
      font-size: 1rem;
    }
  }

  .image-container {
    box-shadow: 0 2px 14px 0 rgba(0,0,0,0.1);
  }

  .row1 p {
    margin: 5px 0 5px 10px;
    text-align: left;
  }

  .row2 {
    display: none;
  }
}
</style>
