<template>
  <div
    class="search-bar"
    :class="device + '-search-bar'"
  >
    <label
      for="search"
    />
    <input
      v-bind="$attrs"
      id="search"
      v-model="query"
      :placeholder="$t('Search.placeholder')"
      name="search"
      class="search-input"
      @keyup.enter="search"
    >
    <img
      v-if="!mq.phone && query !== ''"
      class="erase-icon"
      src="@/assets/cross_black.png"
      alt="erase icon"
      @click="eraseSearch"
    >
    <img
      v-else
      class="search-icon"
      :title="$t('Search.placeholder')"
      src="@/assets/icon_search.svg"
      alt="search icon"
      @click="search"
    >

    <div
      class="catalog-toggle"
      @click="toggleCatalogPopover"
    >
      <img
        src="@assets/icon_indicateur.svg"
        alt=""
        width="30"
      >
    </div>

    <CatalogPopover
      v-if="catalogDisplayed"
    />
  </div>
</template>

<script>
import CatalogPopover from '@components/Indicator/Catalog/CatalogPopover'

export default {
  name: 'SearchBar',
  components: {
    CatalogPopover
  },
  inject: ['mq'],
  data () {
    return {
      inputText: '',
      catalogDisplayed: false
    }
  },
  computed: {
    device () {
      if (this.mq.phone) {
        return 'phone'
      } else {
        return 'desktop'
      }
    },
    query: {
      get () {
        return this.$store.state.search.query
      },
      set (query) {
        this.$store.commit('search/setQuery', query)
      }
    }
  },
  methods: {
    eraseSearch () {
      this.query = ''
    },
    search ($event) {
      $event.target.blur()
      this.$router.push({ name: 'search', params: { query: encodeURIComponent(this.query) } })
    },
    toggleCatalogPopover () {
      this.catalogDisplayed = !this.catalogDisplayed
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@design';

  .search-bar {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    background-color: $search-bg;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.15);
    z-index: $banner-z-index;
  }

  .desktop-search-bar {
    height: $desktop-search-bar-height;
  }

  .phone-search-bar {
    height: $mobile-search-bar-height;
    border-radius: 6px;
  }

  input {
    margin-left: 5%;
    flex: 1;
    font-family: inherit;
    box-shadow: none;
    outline: none;
    border: 0;
  }

  .search-icon {
    margin-left: auto;
    margin-right: 5%;
    height: 23px;
    width: 23px;
    cursor: pointer;
  }

  .erase-icon {
    margin-left: auto;
    margin-right: 5%;
    height: 14px;
    width: 14px;
    cursor: pointer;
  }

  .catalog-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    min-width: 60px;
    border-left: 1px solid #D9E2EA;
    cursor: pointer;
  }

</style>
