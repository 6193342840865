<template>
  <div
    class="indicator"
    :class="[device + '-indicator', {'is-selectable': (!indicator.isHidden) && ((!adminMode && isCatalogItem) || (adminMode && isInCatalogManager))}]"
    :draggable="isDraggable"
    @mouseover="isHovering = true"
    @mouseleave="isHovering = false"
    @dragstart.stop="dragStart"
    @dragend.prevent
    @dragenter.prevent
    @dragover.prevent
    @dragleave.prevent
    @drop="drop"
  >
    <div
      class="row1"
      :class="device + '-row1'"
    >
      <div
        class="image-container"
        @click="selectIndicator"
      >
        <img
          class="image"
          :class="{'selected': isDisplayed, 'hover': isHovering, 'maintenance': indicator.isHidden, 'isNew': isNew && isCatalogItem}"
          :src="indicator.thumbnailUrl"
          alt="img"
        >
        <div
          v-if="indicator.isHidden"
          class="maintenance-icon"
        >
          <img
            src="@/assets/icon_ico-cadyco-maintenance.svg"
            alt="maintenance icon"
          >
        </div>
        <div
          v-if="isNew && isCatalogItem"
          v-t="'Indicators.new'"
          class="is-new-label"
        />
      </div>
      <p>
        {{ indicator.title }}
      </p>
    </div>
    <div class="row2">
      <div
        class="options"
      >
        <CadycoIconOption
          v-if="!adminMode && isCatalogItem && !isInDefaultCatalog"
          class="option"
          :icon="require('@assets/icon_share.svg')"
          :icon-white="require('@assets/icon_share_white.svg')"
          :force-hovering="isHovering"
          icon-height="14px"
          :title="$t('ContextMenuOptions.share')"
          alt="share"
          @click="share"
        />
        <CadycoIconOption
          v-if="!adminMode && (isInPost || (isCatalogItem && isInDefaultCatalog))"
          class="option"
          :icon="require('@assets/icon_indicateur_ajout.svg')"
          :force-hovering="isHovering"
          icon-height="20px"
          :title="$t('ContextMenuOptions.addToCatalog')"
          alt="add to catalog"
          @click.stop="toggleCategorySelection"
        />
        <CadycoIconOption
          v-if="adminMode && isInPost"
          class="option"
          :icon="require('@assets/icon_trash.svg')"
          :icon-white="require('@assets/options/icon_trash_white.svg')"
          :force-hovering="isHovering"
          icon-height="14px"
          :title="$t('ContextMenuOptions.delete')"
          alt="trash"
          @click.stop="removeIndicator"
        />
        <CadycoIconOption
          v-if="adminMode && !isInPost"
          class="option"
          :icon="require('@assets/icon_trash.svg')"
          :force-hovering="isHovering"
          icon-height="14px"
          :title="$t('ContextMenuOptions.delete')"
          alt="trash"
          @click="throwWarning"
        />
        <CadycoIconOption
          v-if="adminMode && !isCatalogItem && !isInPost"
          class="option"
          :icon="require('@assets/options/icon_edit_texte.svg')"
          :force-hovering="isHovering"
          icon-height="16px"
          :title="$t('ContextMenuOptions.modify')"
          alt="update"
          @click="updateIndicator"
        />
        <BaseIcon
          v-if="adminMode && isCatalogItem && isInCatalogManager"
          class="option base-icon"
          :class="{'publicActive': indicator.isPublic}"
          :title="$t('ContextMenuOptions.publicIndicator')"
          name="globe"
          @click="togglePublicField"
        />
      </div>
    </div>
    <SelectCategory
      v-if="isCategorySelection"
      class="categories"
      @close="isCategorySelection=false"
      @categorySelect="addIndicatorInCategory"
    />
  </div>
</template>

<script>
import BaseIcon from '@components/Base/BaseIcon'
import SelectCategory from '@components/Indicator/Catalog/SelectCategory'
import CadycoIconOption from '@components/Base/CadycoIconOption'

export default {
  name: 'IndicatorItem',
  components: {
    CadycoIconOption,
    SelectCategory,
    BaseIcon
  },
  inject: ['mq'],
  props: {
    adminMode: {
      type: Boolean,
      default: false
    },
    catalogId: {
      type: String,
      default: '-1'
    },
    categoryId: {
      type: String,
      default: '-1'
    },
    indicator: {
      type: Object,
      required: true
    },
    displayOptions: {
      type: Boolean,
      default: true
    },
    isInDefaultCatalog: {
      type: Boolean,
      default: false
    },
    isInPost: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      required: true
    }
  },
  emits: ['addToCatalog', 'click', 'drop', 'openUpdateModal', 'remove'],
  data () {
    return {
      isCategorySelection: false,
      isHovering: false
    }
  },
  computed: {
    device () {
      if (this.mq.phone) {
        return 'phone'
      } else {
        return 'desktop'
      }
    },
    user () {
      return this.$store.state.auth.user
    },
    newUserIndicators () {
      return this.$store.state.catalog.unreadProfileIndicators || []
    },
    isNew () {
      return (this.newUserIndicators.map(item => item.indicatorId).indexOf(this.indicator.indicatorId) !== -1) && this.isInDefaultCatalog
    },
    isCatalogItem () {
      return (this.categoryId > -1)
    },
    isDisplayed () {
      return (this.$route.params.indicatorId === this.indicator.indicatorId)
    },
    isDraggable () {
      return this.adminMode && this.isCatalogItem
    },
    isInCatalogManager () {
      return this.$route.name === 'catalogmanager'
    }
  },
  methods: {
    toggleCategorySelection () {
      this.isCategorySelection = !this.isCategorySelection
    },
    updateIndicator () {
      this.$emit('openUpdateModal', this.indicator)
    },
    deleteIndicator () {
      if (this.isCatalogItem) {
        this.$store.dispatch('catalog/deleteIndicator', { catalogId: this.catalogId, categoryId: this.categoryId, indicatorId: this.indicator.indicatorId })
      } else {
        this.$store.dispatch('library/deleteIndicator', this.indicator.indicatorId)
      }
    },
    togglePublicField () {
      const params = {
        catalogId: this.catalogId,
        categoryId: this.categoryId,
        indicatorId: this.indicator.indicatorId,
        position: this.indicator.position,
        isPublic: !this.indicator.isPublic
      }
      this.$store.dispatch('catalog/updateIndicatorInCategory', params)
    },
    selectIndicator () {
      // Manage isNew property
      if (this.isNew && this.isCatalogItem) {
        this.$store.dispatch('catalog/markIndicatorHasRead', this.indicator)
      }
      // Handle click
      if (!this.indicator.isHidden || this.user.isAdministrator || this.user.isCadycoAdministrator) {
        // If catalog consultation redirect to indicator display page
        if ((!this.adminMode && this.isCatalogItem) || (this.adminMode && this.isInCatalogManager)) {
          // if (this.$route.name !== routeName) {
          if (this.isInCatalogManager) {
            this.$router.push({ name: 'adminIndicator', params: { indicatorId: this.indicator.indicatorId } })
          } else {
            this.$router.push({ name: 'indicator', params: { indicatorId: this.indicator.indicatorId } })
          }
        } else {
          this.$emit('click')
        }
      } else {
        this.$store.dispatch('popups/pushPopup', { message: this.$t('Popup.indicatorInMaintenance') })
      }
    },
    addIndicatorInCategory (category) {
      this.$store.dispatch('catalog/addIndicatorInCategory', {
        catalogId: this.$store.state.catalog.userCatalog.catalogId,
        categoryId: category.categoryId,
        position: category.indicatorList.length + 1,
        isPublic: false,
        indicator: this.indicator
      })
    },
    addToCatalog () {
      this.$emit('addToCatalog')
    },
    removeIndicator () {
      this.$emit('remove')
    },
    share () {
      this.$store.dispatch('post/setFile', undefined)
      this.$store.dispatch('post/setIndicator', this.indicator)
      this.$store.dispatch('modals/openCreatePostModal')
    },
    throwWarning () {
      if (this.isCatalogItem) { // Not display warning modal if we are in a catalog
        this.deleteIndicator()
      } else {
        this.$store.dispatch('warningModal/addWarning', {
          text: this.$t('router.views.Catalog.Warning.deleteIndicator'),
          lastAction: { fct: this.deleteIndicator }
        })
      }
    },
    dragStart (e) {
      if (this.isDraggable) {
        const data = { draggedIndicator: this.indicator, sourceCategoryId: this.categoryId }
        e.dataTransfer.setData('indicator', JSON.stringify(data))
      }
    },
    drop (e) {
      if (e.dataTransfer.getData('indicator')) {
        const movedIndicator = JSON.parse(e.dataTransfer.getData('indicator'))

        if (this.categoryId === movedIndicator.sourceCategoryId) { // only drop if indicator is in the same category  // TODO: think about allow drop between categories?
          const params = {
            catalogId: this.catalogId,
            categoryId: movedIndicator.sourceCategoryId,
            indicatorId: movedIndicator.draggedIndicator.indicatorId,
            position: (this.index + 1),
            isPublic: movedIndicator.draggedIndicator.isPublic
          }
          this.$store.dispatch('catalog/updateIndicatorInCategory', params)
        }

        e.stopPropagation()
      } else {
        this.$emit('drop', e) // this component is not concern by the detected drop
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@design";

.indicator {
  display: flex;
  text-align: center;
  position: relative;
  color: $color-cadyco-dark-text;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 23px;

  &:hover {
    color: $color-hover-cadyco;
  }
}

p {
  margin-top: 16px;
}

.is-selectable {
  .row1 {
    cursor: pointer;
  }
}

.options {
  padding-left: 6px;
  padding-right: 8px;
  padding-top: 3px;
  display: flex;
  flex-direction: column;

  .option {
    margin-bottom: 7px;

    &.base-icon {
      margin-left: 9px;
    }
  }

  .publicActive{
    color: #36d917;

    &:hover {
      color: #97E486;
    }
  }
}

.image-container {
  position: relative;
  text-align: center;
  background-color: white;

  .image {
    display: block;
    width: 100px;
    height: 100px;
    border-radius: 6px;
    border: 1px solid transparent;
    box-shadow: 0 2px 14px 0 rgba(0,0,0,0.1);

    &.selected, &.hover {
      border: 1px solid $color-hover-cadyco;
    }

    &.maintenance {
      opacity: 0.3;
    }
  }

  .maintenance-icon {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    background: linear-gradient(319.89deg, #EDEDED 0%, #FFFFFF 100%);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .is-new-label {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 72px;
    height: 17px;
    background-color: white;
    border: 1px solid $color-cadyco-dark-text;
    color: $color-cadyco-dark-text;
    font-size: 0.625em;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 15px;
  }
}

.phone-indicator {
  width: 100%;
  margin-bottom: 20px;

  .phone-row1 {
    flex: 1;
    display: flex;
    align-items: center;

    p {
      margin-left: 20px;
      flex: 1;
      text-align: left;
      margin-top: 0;
    }
  }

  .image {
    width: 70px;
    height: 70px;
  }

  .maintenance-icon {
    width: 42px;
    height: 42px;

    img{
      width: 22px;
    }
  }

  .is-new-label {
    width: 50px;
  }

  .row2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .options {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  }
}

.categories {
  position: absolute;
  top: 30px;
  left: 70px;
  width: 300px;
  max-height: 350px;
  overflow-y: auto;
}
</style>
