<template>
  <div
    class="add-indicator"
    :class="device + '-add-indicator'"
  >
    <div
      class="img-container"
      @click.stop="handleClick"
    >
      <img
        src="@assets/icon_indicateur_ajout.svg"
        alt="add-indicator"
      >
    </div>
    <p
      v-t="'router.views.Catalog.addIndicatorHeader'"
      @click.stop="handleClick"
    />

    <LibraryPopover
      v-if="libraryPopoverDisplayed"
      :catalog-id="catalogId"
      :category="category"
      class="popover"
      @close="toggleLibraryPopover"
    />
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  name: 'AddIndicatorItem',
  components: {
    LibraryPopover: defineAsyncComponent(() =>
      import('@/components/Indicator/Library/LibraryPopover')
    )
  },
  inject: ['mq'],
  props: {
    catalogId: {
      type: String,
      required: true
    },
    category: {
      type: Object,
      required: true
    }
  },
  emits: ['displayIndicatorForm'],
  data () {
    return {
      libraryPopoverDisplayed: false
    }
  },
  computed: {
    device () {
      if (this.mq.phone) {
        return 'phone'
      } else {
        return 'desktop'
      }
    }
  },
  methods: {
    handleClick () {
      if (this.catalogId === '-1') { // that'ss means we are in the library
        this.$emit('displayIndicatorForm')
      } else {
        this.toggleLibraryPopover()
      }
    },
    toggleLibraryPopover () {
      this.libraryPopoverDisplayed = !this.libraryPopoverDisplayed
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@design';

.add-indicator {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  color: $color-cadyco-dark-text;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 23px;

  p {
    width: 100%;
    text-align: center;
    margin-top: 16px;
  }

  .img-container {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    border: 1px solid silver;
    border-radius: 6px;

    .img {
      height: 25px;
    }
  }

  .popover {
    position: absolute;
    top: 84px;
    right: 0;
  }

  &:hover {
    color: $color-hover-cadyco;
    .img-container {
      border-color: $color-hover-cadyco;
    }
  }
}

.phone-add-indicator {
  flex-direction: row;
  position: relative;

  .img-container {
    width: 70px;
    height: 70px;
    margin-right: 20px;
  }

  p {
    width: auto;
  }

  .popover {
    position: absolute;
    top: 0;
    left: -20px;
  }
}

</style>
