<template>
  <div class="category-selection">
    <div
      v-t="'SelectCategory.selectCategoryLabel'"
      class="header"
    />
    <div class="separator" />
    <div
      v-for="(category, index) in categoryList"
      :key="index"
      class="category"
      @click="selectCategory(category)"
    >
      {{ category.title }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectCategory',
  emits: ['categorySelect', 'close'],
  computed: {
    categoryList () {
      return this.$store.state.catalog.userCatalog ? this.$store.state.catalog.userCatalog.categoryList : undefined
    }
  },
  mounted () {
    if (this.$store.state.catalog.userCatalog === undefined) {
      this.$store.dispatch('catalog/initUserCatalog')
    }
    window.addEventListener('click', this.clickOutside)
  },
  beforeUnmount () {
    window.removeEventListener('click', this.clickOutside)
  },
  methods: {
    clickOutside (e) {
      const self = this
      if (self.$el && !self.$el.contains(e.target)) {
        this.close()
      }
    },
    selectCategory (category) {
      this.$emit('categorySelect', category)
      this.close()
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.category-selection {
  cursor: default;
  z-index: 900;
  background-color: white;
  border: 1px solid;
  border-radius: 20px;
  padding: 20px;
}

.separator {
  margin: 10px;
  border-top: 1px solid rgba(50, 50, 50, 0.5);
}

.category {
  cursor: pointer;
  margin-top: 5px;
  padding: 10px;
  margin-bottom: 5px;
  font-weight: bold;
  color: #808080;
  overflow: auto;

  &:hover {
    background-color: #ddd;
  }
}

</style>
