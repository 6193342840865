<template>
  <div
    class="side-menu"
    :class="collapsed ? 'side-menu-collapsed' : 'side-menu-extended'"
  >
    <router-link
      class="root-logo"
      to="/"
    >
      <img
        v-if="!collapsed"
        class="picture"
        src="@/assets/logo_CaDyCo_white.png"
        alt="CaDyCo logo"
      >
      <img
        v-else
        class="picture"
        src="@/assets/logo_bright.png"
        alt="CaDyCo logo"
      >
    </router-link>

    <div class="separator">
      <div class="separator1" />
      <div class="separator2" />
    </div>

    <div class="item-list">
      <SideMenuItem
        :icon="require('@/assets/menu/menu_dashboard.svg')"
        :service-name="$t('router.views.DashBoard.title')"
        :collapsed="collapsed"
        service-route="dashboard"
        @click="changeRoute('dashboard')"
      />
      <SideMenuItem
        :icon="require('@/assets/menu/menu_portfolio.svg')"
        :service-name="$t('router.views.Documents.title')"
        :collapsed="collapsed"
        service-route="portfolio"
        @click="changeRoute('portfolio')"
      />
      <SideMenuItem
        :icon="require('@/assets/menu/menu_messaging.svg')"
        :service-name="$t('router.views.Messaging.title')"
        :collapsed="collapsed"
        service-route="messaging"
        :nb-notifications="nbMessagingNotifications"
        @click="changeRoute('messaging')"
      />
      <SideMenuItem
        :icon="require('@/assets/menu/menu_commu.svg')"
        :service-name="$t('router.views.Communities.title')"
        :collapsed="collapsed"
        service-route="communities"
        @click="changeRoute('communities')"
      />
      <SideMenuItem
        :icon="require('@/assets/menu/menu_contacts.svg')"
        :service-name="$t('router.views.Contacts.title')"
        :collapsed="collapsed"
        service-route="contacts"
        @click="changeRoute('contacts')"
      />
      <SideMenuItem
        :icon="require('@/assets/menu/menu_indicators.svg')"
        :service-name="$t('router.views.Indicators.title')"
        :collapsed="collapsed"
        service-route="catalog"
        :nb-notifications="nbIndicatorNotifications"
        @click="changeRoute('catalog')"
      />
      <SideMenuItem
        v-if="isAdministrator || isCadycoAdministrator"
        :icon="require('@/assets/menu/menu_catalog.svg')"
        :service-name="$t('router.views.Catalog.title')"
        :collapsed="collapsed"
        service-route="catalogmanager"
        @click="changeRoute('catalogmanager')"
      />
      <SideMenuItem
        :icon="require('@/assets/menu/menu_news.svg')"
        :service-name="$t('News.eventTitle')"
        :collapsed="collapsed"
        service-route="catalogmanager"
        @click="changeRoute('events')"
      />
      <SideMenuItem
        v-if="isAdministrator || isCommunicationManager"
        :icon="require('@/assets/menu/menu-admin-news.svg')"
        :service-name="$t('News.adminEventTitle')"
        :collapsed="collapsed"
        service-route="newsmanager"
        @click="changeRoute('newsmanager')"
      />
      <SideMenuItem
        :icon="require('@/assets/menu/icon-cadyco-formation.svg')"
        :service-name="$t('router.views.CadycoObjects.title')"
        :collapsed="collapsed"
        service-route="cadycoObjects"
        @click="changeRoute('cadycoObjects')"
      />
      <SideMenuItem
        v-if="isAdministrator || isCadycoAdministrator"
        :icon="require('@/assets/menu/menu_admin.svg')"
        :service-name="$t('router.views.AdminPanel.title')"
        :collapsed="collapsed"
        service-route="adminPanel"
        @click="changeRoute('adminPanel')"
      />
    </div>

    <div class="nav_footer">
      <div class="separator footer-separator">
        <div class="separator1" />
        <div class="separator2" />
      </div>

      <!-- My parameters -->
      <SideMenuItem
        :icon="userPicture"
        :service-name="formattedUserName"
        :collapsed="collapsed"
        service-route="account"
        @click="changeRoute('account')"
      />
      <SideMenuItem
        :icon="require('@/assets/menu/menu_deco.svg')"
        :service-name="$t('SideMenu.logout')"
        :collapsed="collapsed"
        service-route="logout"
        @click="logout"
      />
    </div>
  </div>
</template>

<script>
import SideMenuItem from './SideMenuItem.vue'
import messagingUtils from '@utils/messaging.utils'

export default {
  name: 'SideMenu',
  components: {
    SideMenuItem
  },
  props: {
    collapsed: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    user () {
      return this.$store.state.auth.user
    },
    formattedUserName () {
      if (this.user) {
        return this.user.firstName
      } else {
        return ''
      }
    },
    userPicture () {
      if (this.user) {
        return this.user.picture
      } else {
        return ''
      }
    },
    isAdministrator () {
      if (this.user) {
        return this.user.isAdministrator
      }
      return false
    },
    isCadycoAdministrator () {
      if (this.user) {
        return this.user.isCadycoAdministrator
      }
      return false
    },
    isCommunicationManager () {
      if (this.user) {
        return this.user.isCommunicationManager
      }
      return false
    },
    nbMessagingNotifications () {
      return this.$store.state.messaging.nbNewMessages
      // return 3
    },
    nbIndicatorNotifications () {
      return this.$store.state.catalog.unreadProfileIndicators.length
    }
  },
  created () {
    // Get services notifications
    this.$store.dispatch('catalog/getUnreadProfileIndicators')
    messagingUtils.updateNbNewMessages()
  },
  methods: {
    changeRoute (routeName) {
      if (this.$route.name !== routeName) {
        this.$router.push({ name: routeName })
      }
    },
    logout: function () {
      this.$store.dispatch('auth/logout')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@design';

.side-menu{
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: $color-menu-bg;
  box-shadow: 0 2px 16px 0 rgba(0,0,0,0.1);

  .root-logo {
    display: flex;
    align-items: center;
  }

  .separator {
    padding-top: 10px;
    padding-bottom: 10px;
    .separator1 {
      height: 2px;
      width: 100%;
      background-color: #032741;
    }
    .separator2 {
      height: 1px;
      width: 100%;
      background-color: #28628B;
    }
  }

  .nav_footer {
    margin-top: auto;

    .account,.logout {
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 20px;
      display: flex;
      align-items: center;
      cursor: pointer;

      &:hover {
        background-color: red;
      }
    }

    img {
      width: 34px;
      height: 34px;
    }

    .user-picture {
      border-radius: 50px;
    }

    span {
      padding-left: 20px;
      font-size: 14px;
      font-weight: bold;
      text-transform: uppercase;
      color: white;
    }
  }
}

.side-menu-extended {
  .root-logo {
    height: 94px;
    .picture {
      padding: 10px;
      max-height: 80px;
      margin-right: 0.5rem;
    }
  }
}

.side-menu-collapsed {
  .root-logo {
    height: $desktop-search-bar-height;
    justify-content: center;
    .picture {
      max-height: 41px;
    }
  }

  .separator {
    padding-top: 0;
    padding-bottom: 10px;
  }

  .footer-separator{
    padding-bottom: 10px;
  }
}

</style>
