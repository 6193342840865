<template>
  <div class="content">
    <div
      v-if="adminMode || indicatorList.length > 0"
      :class="device + '-ind-list'"
    >
      <IndicatorItem
        v-for="(indicator, index) in filteredList"
        :key="indicator.title"
        :index="index"
        :admin-mode="adminMode"
        :is-in-default-catalog="isInDefaultCatalog"
        :catalog-id="catalogId"
        :category-id="category.categoryId"
        :indicator="indicator"
        @openUpdateModal="openUpdateModal"
        @click="selectIndicator(indicator)"
      />
      <AddIndicatorItem
        v-if="adminMode"
        :catalog-id="catalogId"
        :category="category"
        @displayIndicatorForm="displayIndicatorForm"
      />
    </div>
    <h3
      v-else
      v-t="'router.views.Catalog.emptyCategory'"
    />
  </div>
</template>

<script>
import IndicatorItem from '@components/Indicator/Library/IndicatorItem'
import AddIndicatorItem from '@components/Indicator/AddIndicatorItem'

export default {
  name: 'IndicatorList',
  components: {
    AddIndicatorItem,
    IndicatorItem
  },
  inject: ['mq'],
  props: {
    adminMode: {
      type: Boolean,
      default: false
    },
    isInDefaultCatalog: {
      type: Boolean,
      default: false
    },
    catalogId: {
      type: String,
      default: '-1'
    },
    category: {
      type: Object,
      default () {
        return {
          categoryId: '-1'
        }
      }
    },
    indicatorList: {
      type: Array,
      required: true
    },
    hiddenList: {
      type: Array,
      default: () => []
    }
  },
  emits: ['selectIndicator', 'openUpdateModal', 'displayIndicatorForm'],
  computed: {
    filteredList () {
      return (this.hiddenList.length === 0) ? this.indicatorList
        : this.indicatorList.filter((el) => !this.hiddenList.find(rm => (el.indicatorId === rm.indicatorId)))
    },
    device () {
      if (this.mq.phone) {
        return 'phone'
      } else {
        return 'desktop'
      }
    }
  },
  methods: {
    selectIndicator (indicator) {
      this.$emit('selectIndicator', indicator)
    },
    displayIndicatorForm () {
      this.$emit('displayIndicatorForm')
    },
    openUpdateModal (indicator) {
      this.$emit('openUpdateModal', indicator)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@design";

.content {
  background-color: $dark-white-bg;
  padding: 20px;
}

.desktop-ind-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, 130px);
  gap: 15px 30px;
}

.phone-ind-list {
  display: flex;
  flex-direction: column;
}
</style>
