<template>
  <div class="main-layout">
    <SideMenu
      v-if="!mq.phone"
      class="side-menu"
      :class="{'side-menu-collapsed': sideMenuCollapsed}"
      :collapsed="sideMenuCollapsed"
    />

    <div class="main-body">
      <SearchBar
        v-if="!mq.phone"
        @focus="showSearchHistory"
        @blur="hideSearchHistory"
      />
      <SearchHistory v-if="isSearchHistoryDisplayed" />
      <slot v-else />
      <MobileMenu v-if="mq.phone" />
    </div>

    <div
      class="popups-container"
      :class="mq.phone ? 'phone-popup-container' : ''"
    >
      <Popup
        v-for="(popup, index) in popupList"
        :key="index"
        class="popup"
        color="#0B3C5F"
        :message="popup.message"
        :timeout="popupTimeout"
        @reachTimeout="closePopup"
      />
    </div>

    <!-- global modals -->
    <teleport to="body">
      <ImagePickerModal v-if="isImagePickerModalDisplayed" />
      <CreateFolderModal v-if="isCreateFolderModalDisplayed" />
      <CreatePostModal v-if="isCreatePostModalDisplayed" />
      <RenameModal v-if="isRenameModalDisplayed" />
      <WarningModal v-if="isWarningModalDisplayed" />
      <FormationDetailsModal v-if="isFormationDetailsModalDisplayed" />
      <EtablissementDetailsModal v-if="isEtablissementDetailsModalDisplayed" />
    </teleport>
  </div>
</template>

<script>
import SideMenu from '@components/SideMenu/SideMenu'
import { mapGetters } from 'vuex'
import appConstants from '@/constants/appConstants'
import SearchBar from '@/components/Search/SearchBar'

import { defineAsyncComponent } from 'vue'
const EtablissementDetailsModal = defineAsyncComponent(() => import('@components/Modals/CadycoSheets/EtablissementDetailsModal'))
const FormationDetailsModal = defineAsyncComponent(() => import('@components/Modals/CadycoSheets/FormationDetailsModal'))
const MobileMenu = defineAsyncComponent(() => import('@components/MobileMenu/MobileMenu'))
const SearchHistory = defineAsyncComponent(() => import('@components/Search/SearchHistory'))
const RenameModal = defineAsyncComponent(() => import('@components/Documents/RenameModal'))
const CreateFolderModal = defineAsyncComponent(() => import('@components/Documents/CreateFolderModal'))
const CreatePostModal = defineAsyncComponent(() => import('@components/socialNetwork/CreatePostModal'))
const WarningModal = defineAsyncComponent(() => import('@components/Modals/WarningModal'))
const ImagePickerModal = defineAsyncComponent(() => import('@components/Modals/ImagePickerModal'))

export default {
  components: {
    CreateFolderModal,
    CreatePostModal,
    EtablissementDetailsModal,
    FormationDetailsModal,
    ImagePickerModal,
    MobileMenu,
    RenameModal,
    SearchBar,
    SearchHistory,
    SideMenu,
    WarningModal
  },
  inject: ['mq'],
  data () {
    return {
      isSearchHistoryDisplayed: false
    }
  },
  computed: {
    sideMenuCollapsed () {
      return this.device === 'limit1' || this.device === 'limit2'
    },
    device () {
      if (this.mq.phone) {
        return 'phone'
      } else if (this.mq.projectSizeLimit1) {
        return 'limit1'
      } else if (this.mq.projectSizeLimit2 || this.mq.tablet) {
        return 'limit2'
      } else {
        return 'desktop'
      }
    },
    searchQuery () {
      return this.$store.state.search.query
    },
    popupTimeout () {
      return appConstants.popupDurationTime
    },
    isImagePickerModalDisplayed () {
      return this.$store.state.modals.imagePickerModal.isDisplayed
    },
    isCreateFolderModalDisplayed () {
      return this.$store.state.modals.isCreateFolderModalDisplayed
    },
    isCreatePostModalDisplayed () {
      return this.$store.state.modals.isCreatePostModalDisplayed
    },
    isRenameModalDisplayed () {
      return this.$store.state.modals.renameModal.isDisplayed
    },
    isWarningModalDisplayed () {
      return this.$store.getters['warningModal/isWarningModalDisplayed']
    },
    isFormationDetailsModalDisplayed () {
      return this.$store.state.modals.isFormationDetailsModalDisplayed
    },
    isEtablissementDetailsModalDisplayed () {
      return this.$store.state.modals.isEtablissementDetailsModalDisplayed
    },
    popupList () {
      return this.$store.state.popups.currentPopupList
    },
    ...mapGetters('auth', [
      'isAuthenticated'
    ])
  },
  beforeCreate () {
    // Get authenticated user infos if not already defined
    if (!this.$store.state.auth.user && this.$store.getters['auth/isAuthenticated']) {
      this.$store.dispatch('auth/getUserInfos')
    }
  },
  methods: {
    closePopup () {
      this.$store.dispatch('popups/popPopup')
    },
    hideSearchHistory () {
      const vm = this
      setTimeout(function () { vm.isSearchHistoryDisplayed = false }, 500)
    },
    showSearchHistory () {
      this.isSearchHistoryDisplayed = true
    }
  }
}
</script>

<style lang="scss">
@import '@design';

.modal-mask.mobile {
  z-index: $modal-z-index;
}
</style>

<style lang="scss" scoped>
@import '@design';

.main-layout {
  display: flex;
  width: 100%;
  height: 100%;
  margin: auto;

  .side-menu {
    z-index: $menu-z-index;
    grid-area: menu;
    min-width: $side-menu-width;
    height: 100%;
    text-overflow: ellipsis;
    box-shadow: 1px 1px 6px #888;
  }

  .side-menu-collapsed {
    width: $side-menu-collapsed-width;
    min-width: $side-menu-collapsed-width;
  }

  .main-body {
    z-index: $body-z-index;
    width: 100%;
    height: 100%;
  }

  .popups-container {
    position: absolute;
    top: calc(#{$desktop-search-bar-height} + 16px);
    right: 0;
    z-index: $popup-z-index;
    flex-direction: column;

    .popup {
      color: white;
    }
  }

  .phone-popup-container {
    top: 16px;
    right: 50%;
    transform: translate(50%, 0);

    .popup {
      width: 90vw;
      height: 50px;
    }
  }
}

</style>
