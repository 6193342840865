<template>
  <div>
    <template v-if="categoryList.length > 0">
      <CategoryItem
        v-for="(category, index) in categoryList"
        :key="category.categoryId"
        :admin-mode="adminMode"
        :is-in-default-catalog="isInDefaultCatalog"
        :catalog-id="catalogId"
        :category="category"
        :index="index"
      />
    </template>
    <h2
      v-else-if="!adminMode"
      v-t="'router.views.Catalog.emptyCatalog'"
    />
    <div
      v-if="adminMode"
      class="create-category"
    >
      <div
        class="header"
        @click="addCategory"
      >
        <img
          src="@assets/icon_add_element.svg"
          alt="add-icon"
        >
        <h3 v-t="'router.views.Catalog.addCategory'" />
      </div>
      <label>
        <input
          ref="title"
          v-model="title"
          class="title"
          :placeholder="$t('router.views.Catalog.addCategory')"
          @keyup.exact.enter.stop="addCategory"
        >
      </label>
    </div>
  </div>
</template>

<script>
import CategoryItem from '@components/Indicator/Catalog/CategoryItem'

export default {
  name: 'CategoryList',
  components: { CategoryItem },
  props: {
    adminMode: {
      type: Boolean,
      default: false
    },
    isInDefaultCatalog: {
      type: Boolean,
      default: false
    },
    catalogId: {
      type: String,
      default: '-1'
    },
    categoryList: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      title: ''
    }
  },
  methods: {
    addCategory () {
      if (this.title !== '') {
        const category = {
          title: this.title,
          position: this.categoryList.length + 1,
          indicatorList: []
        }
        this.$store.dispatch('catalog/addCategory', { catalogId: this.catalogId, category })
        this.title = ''
      } else {
        const input = this.$refs.title
        input.focus()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@design";

.title {
  padding: 5px;
  background-color: $color-selected-bg;
  border: none;
  border-radius: 10px;
}

.create-category {
  .header {
    display: flex;
    cursor: pointer;
    align-items: center;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 36px;

    img {
      height: 33px;
      margin-right: 20px;
    }
  }

  .title {
    flex: 1;
    font-family: inherit;
    box-shadow: none;
    outline: none;
    background-color: $dark-white-bg;
    border: 0;
    border-radius: 0;
    width: 100%;
    margin-bottom: 50px;
  }
}
</style>
