<template>
  <div
    :draggable="draggable"
    class="item"
    @dragstart.stop="dragStart"
    @dragend="dragEnd"
    @dragenter.prevent
    @dragover.prevent
    @dragleave="dragLeave"
    @drop="drop"
  >
    <div class="header">
      <div class="title">
        <!--        <BaseIcon-->
        <!--          v-if="adminMode"-->
        <!--          class="handle"-->
        <!--          name="arrows-alt"-->
        <!--          @mousedown="allowDrag"-->
        <!--        />-->
        <CadycoIconOption
          v-if="adminMode"
          class="icon"
          :icon="require('@assets/icon_dragDrop_black.svg')"
          :gray-background-color="true"
          icon-height="14px"
          alt="drag"
          @mousedown="allowDrag"
        />
        <CadycoIconOption
          v-if="adminMode"
          class="icon"
          :icon="require('@assets/icon_trash.svg')"
          :icon-white="require('@assets/options/icon_trash_white.svg')"
          :gray-background-color="true"
          icon-height="14px"
          alt="trash"
          @click="throwWarning"
        />
        <input
          v-if="inputDisplayed && adminMode"
          ref="title"
          v-model="title"
          @keyup.exact.enter.stop="updateTitle"
          @keyup.esc.stop="cancelTitleUpdate"
        >
        <h3
          v-else
          @dblclick="showInput"
        >
          {{ category.title }}
        </h3>
      </div>
    </div>
    <IndicatorList
      :admin-mode="adminMode"
      :is-in-default-catalog="isInDefaultCatalog"
      :catalog-id="catalogId"
      :category="category"
      :indicator-list="category.indicatorList"
    />
  </div>
</template>

<script>
import { nextTick } from 'vue'
import IndicatorList from '@components/Indicator/Library/IndicatorList'
import CadycoIconOption from '@components/Base/CadycoIconOption'

export default {
  name: 'CategoryItem',
  components: { CadycoIconOption, IndicatorList },
  props: {
    adminMode: {
      type: Boolean,
      default: false
    },
    isInDefaultCatalog: {
      type: Boolean,
      default: false
    },
    catalogId: {
      type: String,
      default: '-1'
    },
    category: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  emits: ['drop'],
  data () {
    return {
      draggable: false,
      inputDisplayed: false,
      isHovered: false
    }
  },
  methods: {
    allowDrag () {
      this.draggable = true
    },
    cancelTitleUpdate () {
      this.inputDisplayed = false
    },
    deleteCategory () {
      this.$store.dispatch('catalog/deleteCategory', { catalogId: this.catalogId, categoryId: this.category.categoryId })
    },
    dragEnd () {
      this.draggable = false
    },
    dragLeave () {
      this.isHovered = false
    },
    dragStart (e) {
      if (this.draggable) {
        e.dataTransfer.setData('category', JSON.stringify(this.category))
      }
    },
    drop (e) {
      if (e.dataTransfer.getData('category')) {
        const movedCategory = JSON.parse(e.dataTransfer.getData('category'))

        const params = {
          catalogId: this.catalogId,
          categoryId: movedCategory.categoryId,
          title: movedCategory.title,
          position: (this.index + 1)
        }
        this.$store.dispatch('catalog/updateCategory', params)
        e.stopPropagation()
      } else {
        this.$emit('drop', e) // this component is not concern by the detected drop
      }
    },
    showInput () {
      if (this.adminMode) {
        this.title = this.category.title
        this.inputDisplayed = true

        // Focus input
        const vm = this
        nextTick(function () {
          vm.$refs.title.focus()
        })
      }
    },
    throwWarning () {
      this.$store.dispatch('warningModal/addWarning', {
        text: this.$t('router.views.Catalog.Warning.deleteCategory'),
        lastAction: { fct: this.deleteCategory }
      })
    },
    updateTitle () {
      const params = {
        catalogId: this.catalogId,
        categoryId: this.category.categoryId,
        title: this.title,
        position: this.category.position
      }
      this.$store.dispatch('catalog/updateCategory', params)
      // TODO if success hide input
      this.inputDisplayed = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@design";

.item {
  position: relative;
  background-color: #fff;
  /* disable text selection (to prevent untimely drags)  */
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none; /* CSS3 (little to no support) */

  &.dragover {
    background-color: green;
  }
}

.header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .handle {
    cursor: move;
  }

  .title {
    display: flex;
    align-items: center;
    gap: 5px;
    color: $color-cadyco-dark-text;

    .icon {
      margin-right: 16px;
    }
  }

  h3 {
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 36px;
  }

  input {
    padding: 5px;
    background-color: $color-selected-bg;
    border: none;
    border-radius: 10px;
  }
}
</style>
