<template>
  <div class="category">
    <h2 v-if="title">
      {{ title }}
    </h2>
    <CategoryList
      :admin-mode="adminMode"
      :catalog-id="catalog.catalogId"
      :is-in-default-catalog="isDefaultCatalog"
      :category-list="catalog.categoryList"
    />
  </div>
</template>

<script>
import CategoryList from '@components/Indicator/Catalog/CategoryList'

export default {
  name: 'Catalog',
  components: { CategoryList },
  props: {
    adminMode: {
      type: Boolean,
      default: false
    },
    catalog: {
      type: Object,
      required: true
    },
    isDefaultCatalog: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
